import { useQuery } from '@tanstack/react-query';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FormType } from '../../../../models/FormTypes';
import PickListService from '../../../../services/PickListService';
import ConditionsProperties from '../../../form-builder/ConditionsProperties';
import { EditorProps } from '../../../form-builder/FormBuilderTypes';
import PreviewProperties from '../../../form-builder/PreviewProperties';
import RiskProperties from '../../../form-builder/RiskProperties';
import { Item } from '../../../shared/form-control/DropdownDefaultComponents';
import DropdownSelect from '../../../shared/form-control/DropdownSelect';
import { OpenInNewTabValueRendererWrapper } from '../../../shared/form-control/DropdownSharedComponnets';
import ActionEditorBase from '../ActionEditorBase';
import { createPickListSource } from '../PickListSource';
import { useCurrentClient, useCurrentTenantId } from '../../../../global-state/Clients';

const SelectListEditor: FC<EditorProps> = (props) => {
  const { action, patchAction, patchData, form } = props;
  const { sourceType } = action?.data || {};

  const client = useCurrentClient((x) => x.value);
  const currentTenantId = useCurrentTenantId((x) => x.value);

  const { t } = useTranslation(['form-builder', 'organisation']);

  const {
    data: picklistSources = [],
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ['picklists', action.id],
    queryFn: () =>
      PickListService.getAll({ pageSize: 500, pageNumber: 1, clientId: client?.id, includeArchived: true }).then((res) => {
        return createPickListSource(res.data, t);
      }),
  });

  const onSourceTypeChanged = (option: Item) => {
    patchData({ sourceType: option.id });
  };

  const selectedPicklistSource = picklistSources.flatMap((x) => x.options).find((x) => x.id === sourceType);

  const picklistLinkProvider = useCallback(
    (item: Item) => {
      return item.value ? `/clients/${item.value}/picklists/${item.id}` : `/builder/${currentTenantId}/picklists/${item.id}`;
    },
    [currentTenantId],
  );

  return (
    <div data-cy="select-list-editor">
      <ActionEditorBase {...props}>
        {sourceType && <div className="text-dpm-12 mt-4">{t('form-builder:action-properties.select-list.form.source')}</div>}
        <DropdownSelect
          data-cy="source"
          className={`${!sourceType && 'mt-8'}`}
          placeholder={t('form-builder:action-properties.select-list.form.source')}
          value={{
            id: sourceType,
            text: selectedPicklistSource?.text || '',
            value: selectedPicklistSource?.value || '',
            disabled: selectedPicklistSource?.disabled,
          }}
          options={picklistSources}
          onChange={onSourceTypeChanged}
          customListRenderer={OpenInNewTabValueRendererWrapper(picklistLinkProvider)}
          isFetching={isFetching}
          onOpen={refetch}
        />
      </ActionEditorBase>

      <ConditionsProperties {...props} />

      <PreviewProperties {...props} enableDocumentNumbering />

      {(form.type === FormType.Asset || form.type == FormType.SubForm || form.type == FormType.SubFormWithApproval) && (
        <RiskProperties action={action} patchAction={patchAction} form={form} />
      )}
    </div>
  );
};

export default SelectListEditor;
